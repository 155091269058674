const copy = (obj, keys = []) => {
    const copied = JSON.parse(JSON.stringify(obj));

    if (keys.length) {
        for (const k of Object.keys(obj)) {
            keys.includes(k) ? '' : delete copied[k];
        }
    }

    return copied;
};

export function useResetable(initialValue = null, options = {}) {
    // make a deep copy of the initialValue
    const _initial = copy(toRaw(initialValue), options.pick ?? []);

    const value = ref(copy(_initial));

    const body = computed(() => {
        if (!options.excludeEmpty) {
            return value.value;
        }

        const nonEmpty = Object.keys(value.value).filter(
            (k) => value.value[k] !== null
        );

        return copy(value.value, nonEmpty);
    });

    const fill = (data, ignore = []) => {
        if (!ignore || !Array.isArray(ignore)) {
            ignore = [];
        }

        const _data = copy(toRaw(data), options.pick ?? []);

        for (const k of Object.keys(data)) {
            if (ignore.includes(k)) {
                continue;
            }

            value.value[k] = _data[k];
        }
    };

    /**
     * Reset value
     */
    const reset = (pick = null) => {
        value.value = copy(_initial, pick ?? []);
    };

    /**
     * Clear value
     */
    const clear = () => {
        const keys = Object.keys(value.value);

        for (const k of keys) {
            value.value[k] = null;
        }
    };

    return {
        value,
        reset,
        clear,
        fill,
        body,
    };
}
